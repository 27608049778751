import React, { useState } from 'react';
import axios from 'axios';

const RegisterForm = ({ handleRegister, switchToLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [phoneVerificationCode, setPhoneVerificationCode] = useState('');
  const [isPhoneCodeSent, setIsPhoneCodeSent] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [agreePersonalInfo, setAgreePersonalInfo] = useState(false);
  const [agreeAds, setAgreeAds] = useState(false);
  const [showPersonalInfoDetails, setShowPersonalInfoDetails] = useState(false);
  const [showAdsDetails, setShowAdsDetails] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!isPhoneCodeSent) {
      alert("전화번호 인증 코드를 보내주세요.");
      return;
    }

    if (!isPhoneVerified) {
      alert("전화번호 인증을 완료해 주세요.");
      return;
    }

    if (!agreePersonalInfo) {
      alert("개인정보 동의를 체크해 주세요.");
      return;
    }

    handleRegister({ username, password, confirmPassword, name, phone, email, agreeAds });
  };

  const sendPhoneVerificationCode = async () => {
    if (!phone) {
      alert("전화번호를 입력해 주세요.");
      return;
    }

    try {
      let baseURL = "https://api.gosky.kr";
      if (process.env.NODE_ENV === "development") {
        baseURL = "http://api.gosky.kr";
      }
      const response = await axios.post(`${baseURL}/api/gosky/sms/send-code`, {
        phoneNumber: phone,
      });

      if (response.data.success) {
        setIsPhoneCodeSent(true);
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("전화번호 인증 코드 전송 중 오류 발생:", error);
      alert("전화번호 인증 코드 전송 중 오류가 발생했습니다.");
    }
  };

  const verifyPhoneCode = async () => {
    if (!phoneVerificationCode) {
      alert("전화번호 인증 코드를 입력해 주세요.");
      return;
    }

    try {
      let baseURL = "https://api.gosky.kr";
      if (process.env.NODE_ENV === "development") {
        baseURL = "http://api.gosky.kr";
      }
      const response = await axios.post(`${baseURL}/api/gosky/sms/verify-code`, {
        phoneNumber: phone,
        code: phoneVerificationCode,
      });

      if (response.data.success) {
        setIsPhoneVerified(true);
        alert("전화번호 인증이 완료되었습니다.");
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("전화번호 인증 코드 확인 중 오류 발생:", error);
      alert("전화번호 인증 코드 확인 중 오류가 발생했습니다.");
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="input-container">
        <input
          type="text"
          placeholder="ID"
          className="input-field"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="input-container">
        <input
          type="password"
          placeholder="Password"
          className="input-field"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="input-container">
        <input
          type="password"
          placeholder="Confirm Password"
          className="input-field"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <div className="input-container">
        <input
          type="text"
          placeholder="Name"
          className="input-field"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="input-container">
        <input
          type="text"
          placeholder="Phone Number"
          className="input-field"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          disabled={isPhoneCodeSent || isPhoneVerified}
        />
        <button type="button" className="Verification-button" onClick={sendPhoneVerificationCode} disabled={isPhoneCodeSent || isPhoneVerified}>
          Send
        </button>
      </div>
      {isPhoneCodeSent && (
        <div className="input-container">
          <input
            type="text"
            placeholder="Enter Phone Verification Code"
            className="input-field"
            value={phoneVerificationCode}
            onChange={(e) => setPhoneVerificationCode(e.target.value)}
            disabled={isPhoneVerified}
          />
          <button type="button" className="Verification-button" onClick={verifyPhoneCode} disabled={isPhoneVerified}>
            Verify
          </button>
        </div>
      )}
      <div className="input-container">
        <input
          type="email"
          placeholder="Email"
          className="input-field"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="agreePersonalInfo"
          checked={agreePersonalInfo}
          onChange={(e) => setAgreePersonalInfo(e.target.checked)}
        />
        <label htmlFor="agreePersonalInfo"> (필수) 개인정보 동의</label>
        <button
          type="button"
          className="details-button"
          onClick={() => setShowPersonalInfoDetails(!showPersonalInfoDetails)}
        >
          자세히 보기
        </button>
      </div>
      {showPersonalInfoDetails && (
        <div className="details-content">
          <p>
            개인정보 수집 및 이용에 대한 안내: 회원 가입을 위해 이름, 전화번호, 이메일 등의 개인정보를
            수집합니다. 수집된 개인정보는 회원 관리, 서비스 제공 및 개선, 법적 의무 준수를 위해 사용됩니다.
          </p>
        </div>
      )}
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="agreeAds"
          checked={agreeAds}
          onChange={(e) => setAgreeAds(e.target.checked)}
        />
        <label htmlFor="agreeAds"> (선택) 광고성 정보 수신 동의</label>
        <button
          type="button"
          className="details-button"
          onClick={() => setShowAdsDetails(!showAdsDetails)}
        >
          자세히 보기
        </button>
      </div>
      {showAdsDetails && (
        <div className="details-content">
          <p>
            광고성 정보 수신 동의 안내: 마케팅 및 광고 목적으로 회원님의 연락처를 통해 광고성 정보를 발송할 수
            있습니다. 이 동의는 선택사항이며, 언제든지 철회할 수 있습니다.
          </p>
        </div>
      )}
      <button type="submit" className="register-button">
        Register
      </button>
      <p>
        Already have an account?{' '}
        <button type="button" className="signup" onClick={switchToLogin}>
          Login here.
        </button>
      </p>
    </form>
  );
};

export default RegisterForm;
