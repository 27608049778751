import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/Myapge.css"; // Import the CSS file for styling

const Myapge = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  let baseURL = "https://api.gosky.kr";
  if (process.env.NODE_ENV === "development") {
    baseURL = "http://api.gosky.kr";
  }

  useEffect(() => {
    // Retrieve user_id from local storage
    const userId = localStorage.getItem("user_id");

    if (!userId) {
      setError("User ID not found in local storage.");
      return;
    }

    // Construct the API endpoint using the user_id
    axios
      .get(`${baseURL}/api/gosky/users/${userId}`)
      .then((response) => {
        setUserInfo(response.data);
      })
      .catch((error) => {
        setError("Failed to fetch user data");
        console.error(error);
      });
  }, []);

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const getAuthorizationLevel = (level) => {
    switch (level) {
      case 1:
        return "User";
      case 2:
      case 3:
      case 4:
        return "Not specified";
      case 5:
        return "Administrator";
      default:
        return "Unknown";
    }
  };

  const toggleConsent = () => {
    if (!userInfo) return;

    const updatedAgreeAds = userInfo.agree_ads === 1 ? 0 : 1;

    axios
      .put(`${baseURL}/api/gosky/users_consent/${userInfo.user_id}`, {
        agree_ads: updatedAgreeAds,
      })
      .then((response) => {
        setUserInfo((prevInfo) => ({
          ...prevInfo,
          agree_ads: updatedAgreeAds,
        }));
      })
      .catch((error) => {
        setError("Failed to update consent status");
        console.error(error);
      });
  };

  if (error) {
    return <p className="myapge-error-message">{error}</p>;
  }

  if (!userInfo) {
    return (
      <p className="myapge-loading-message">Loading user information...</p>
    );
  }

  return (
    <div className="myapge-container">
      <div className="myapge-content-box">
        <h1 className="myapge-h1">Welcome to the Main Page</h1>
        <p className="myapge-p">This is your main Myapge.</p>
        <h2 className="myapge-h2">User Information</h2>
        <ul className="myapge-ul">
          <li className="myapge-li">
            <strong>Name:</strong> {userInfo.user_name}
          </li>
          <li className="myapge-li">
            <strong>ID:</strong> {userInfo.user_id}
          </li>
          <li className="myapge-li">
            <strong>Email:</strong> {userInfo.user_email}
          </li>
          <li className="myapge-li">
            <strong>Number:</strong> {formatPhoneNumber(userInfo.user_num)}
          </li>
          <li className="myapge-li">
            <strong>Authorization Level:</strong>{" "}
            {getAuthorizationLevel(userInfo.user_auth)}
          </li>
          <li className="myapge-li">
            <strong>광고성 문자 동의:</strong> {userInfo.agree_ads === 1 ? "동의" : "비동의"}{" "}
          </li>
          <button onClick={toggleConsent} className="myapge-toggle-button">
              {userInfo.agree_ads === 1 ? "비동의로 변경" : "동의로 변경"}
          </button>
        </ul>
      </div>
    </div>
  );
};

export default Myapge;
