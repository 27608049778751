import React, { useState, useEffect } from "react";
import axios from 'axios';
import Modal from 'react-modal';

const Cloud = () => {
    const nickname = localStorage.getItem("user_id");
    const [currentPath, setCurrentPath] = useState(nickname + '/');
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isImageFile, setIsImageFile] = useState(false);
    const [isVideoFile, setIsVideoFile] = useState(false);
    const [isDownloadable, setIsDownloadable] = useState(false);
    const [directoryExists, setDirectoryExists] = useState(true);
    const [fileToUpload, setFileToUpload] = useState(null);

    let baseURL = "https://api.gosky.kr";
    if (process.env.NODE_ENV === "development") {
        baseURL = "http://api.gosky.kr";
    }

    // 파일 유형 확인 함수
    const checkFileType = (fileExtension) => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        const videoExtensions = ['mp4', 'webm', 'ogg'];
        setIsImageFile(imageExtensions.includes(fileExtension));
        setIsVideoFile(videoExtensions.includes(fileExtension));
        setIsDownloadable(!imageExtensions.includes(fileExtension) && !videoExtensions.includes(fileExtension));
    };

    // 현재 디렉토리에서 파일 가져오기
    const getFilesInDirectory = async (dir) => {
        try {
            const response = await axios.get(`${baseURL}/api/cloud/check/${dir}`);
            if (response.data.error === '접속한 경로의 디렉토리나 파일이 존재하지 않습니다.') {
                setDirectoryExists(false);
                setMessage('디렉토리가 존재하지 않습니다. 새 디렉토리를 생성하세요.');
            } else {
                setFiles(Array.isArray(response.data) ? response.data : []);
                setMessage('');
                setDirectoryExists(true);
            }
        } catch (error) {
            console.error('경로 파일 조회 실패:', error);
            setFiles([]);
            setMessage(error.response?.data?.error || '오류 발생');
        }
    };

    // 새 디렉토리 생성 처리
    const handleCreateDirectory = async () => {
        try {
            const response = await axios.post(`${baseURL}/api/cloud/create/${nickname}`);
            setMessage(response.data.message || '디렉토리가 생성되었습니다.');
            setDirectoryExists(true);
            getFilesInDirectory(currentPath);
        } catch (error) {
            console.error('디렉토리 생성 실패:', error);
            setMessage(error.response?.data?.error || '디렉토리 생성에 실패했습니다.');
        }
    };

    // 디렉토리 또는 파일 클릭 처리
    const handleFileClick = (file) => {
        if (file.isDirectory) {
            setCurrentPath(`${currentPath}${file.name}/`);
        } else {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            checkFileType(fileExtension);

            const fileUrl = `${baseURL}/api/cloud/check/${currentPath}${file.name}`;
            setModalContent(fileUrl);
            setSelectedFile(file.name);
            setIsModalOpen(true);
        }
    };

    // 상위 디렉토리로 이동
    const goBack = () => {
        if (currentPath === nickname + '/') {
            alert("최상위 디렉토리를 열람할 권한이 없습니다.");
            return;
        }
        const parentPath = currentPath.slice(0, currentPath.lastIndexOf('/', currentPath.length - 2) + 1);
        setCurrentPath(parentPath || '/');
    };

    // 파일 선택 처리
    const handleFileSelection = (event) => {
        setFileToUpload(event.target.files[0]);
    };

    // 파일 업로드 처리
    const handleFileUpload = async () => {
        if (!fileToUpload) {
            alert('파일을 선택하세요.');
            return;
        }
        const formData = new FormData();
        formData.append('file', fileToUpload);
        formData.append('directory', currentPath); // 현재 경로 (사용자 닉네임 포함)으로 설정
    
        try {
            const response = await axios.post(`${baseURL}/api/cloud/upload_with_original_name/${nickname}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage(response.data.message || '파일이 업로드되었습니다.');
            setFileToUpload(null);
            getFilesInDirectory(currentPath);
        } catch (error) {
            console.error('파일 업로드 실패:', error);
            setMessage(error.response?.data?.error || '파일 업로드에 실패했습니다.');
        }
    };
    

    // 모달 닫기
    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
        setSelectedFile(null);
        setIsImageFile(false);
        setIsVideoFile(false);
        setIsDownloadable(false);
    };

    useEffect(() => {
        getFilesInDirectory(currentPath);
    }, [currentPath]);

    return (
        <div>
            <h1>파일 탐색기</h1>
            <div>
                {message && <p>{message}</p>}
                {!directoryExists ? (
                    <div>
                        <button onClick={handleCreateDirectory}>디렉토리 생성</button>
                    </div>
                ) : (
                    <div>
                        <p>현재 경로: {currentPath}</p>
                            <button onClick={goBack} disabled={currentPath === nickname + '/'}>상위 디렉토리로 이동</button>
                        <div>
                            <input type="file" onChange={handleFileSelection} />
                            <button onClick={handleFileUpload} disabled={!fileToUpload}>파일 업로드</button>
                        </div>
                        <ul style={{ height: '500px', overflowY: 'scroll', textAlign: 'left' }}>
                            {files.map((file, index) => (
                                <li key={index} onClick={() => handleFileClick(file)} style={{ cursor: 'pointer' }}>
                                    {file.isDirectory ? '📁' : '📄'} {file.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            {/* 파일 내용 표시 모달 */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="File Content"
                ariaHideApp={false}
                style={{
                    content: {
                        maxWidth: '80%',
                        maxHeight: '80%',
                        margin: 'auto'
                    },
                }}
            >
                <h2>{selectedFile}</h2>
                <button onClick={closeModal}>닫기</button>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    {isImageFile ? (
                        <img
                            src={modalContent}
                            alt={selectedFile}
                            style={{ maxWidth: '100%', maxHeight: '90vh', objectFit: 'contain' }}
                        />
                    ) : isVideoFile ? (
                        <video
                            controls
                            style={{ maxWidth: '100%', maxHeight: '90vh', objectFit: 'contain' }}
                        >
                            <source src={modalContent} type={`video/${selectedFile.split('.').pop()}`} />
                            Your browser does not support the video tag.
                        </video>
                    ) : isDownloadable ? (
                        <a href={modalContent} download={selectedFile}>
                            {selectedFile} 다운로드
                        </a>
                    ) : (
                        modalContent
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default Cloud;
