import React, { useState, useEffect, useRef } from 'react';
import "../../css/Ws_page.css"; 

// Combined Component
const ChatPage = () => {
  const [userID, setUserID] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [error, setError] = useState('');
  const [friendUsername, setFriendUsername] = useState(''); // Add friend input
  const [ws, setWs] = useState(null); // Track WebSocket instance
  const chatEndRef = useRef(null); // Reference for chat end

  useEffect(() => {
    const storedUserID = localStorage.getItem('user_id');
    if (storedUserID) {
      setUserID(storedUserID);
    } else {
      console.error('No user ID found in localStorage');
    }
  }, []);

  useEffect(() => {
    if (!userID) return;

    const websocket = new WebSocket('wss://gosky.kr:3009');
    setWs(websocket); // Store WebSocket instance

    websocket.onopen = () => {
      console.log('WebSocket connection established');
      websocket.send(JSON.stringify({ type: 'uid', uid: userID }));
    };

    websocket.onmessage = (event) => {
      console.log(`Received message: ${event.data}`);
      const data = JSON.parse(event.data);
      if (data.type === 'message') {
        setMessages((prevMessages) => [...prevMessages, data]);
      }
    };

    websocket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    websocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      websocket.close();
    };
  }, [userID]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        let baseURL = "https://api.gosky.kr";
        if (process.env.NODE_ENV === "development") {
          baseURL = "http://api.gosky.kr";
        }
        const response = await fetch(`${baseURL}/api/gosky/chat-history/${userID}`);
        if (!response.ok) {
          throw new Error('Failed to fetch chat history');
        }
        const data = await response.json();
        setContacts(data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchContacts();
  }, [userID]);

  const fetchChatHistory = async (contactID) => {
    try {
      let baseURL = "https://api.gosky.kr";
      if (process.env.NODE_ENV === "development") {
        baseURL = "http://api.gosky.kr";
      }
      const response = await fetch(`${baseURL}/api/gosky/chat-messages/${userID}/${contactID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch chat history');
      }
      const data = await response.json();
      setMessages(data); // Set the messages for the selected contact
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  const handleContactClick = (contactID) => {
    setSelectedContact(contactID);
    setError(''); // Clear any previous error
    fetchChatHistory(contactID); // Fetch and display chat history for selected contact
  };

  const handleSendMessage = () => {
    if (!selectedContact) {
      setError('Please select a contact to chat with.');
      return;
    }
    if (!newMessage.trim()) return;

    const outgoingMessage = {
      type: 'message',
      senderID: userID,
      recipientID: selectedContact,
      message: newMessage,
    };

    // Update the local state with the new message (this simulates sending the message)
    setMessages((prevMessages) => [...prevMessages, outgoingMessage]);

    // Send the message via WebSocket
    if (ws) {
      ws.send(JSON.stringify(outgoingMessage));
      setNewMessage(''); // Clear the input field
    }
  };

  // Function to handle adding a new friend
  const handleAddFriend = async () => {
    if (!friendUsername.trim()) {
      setError('Please enter a valid username.');
      return;
    }
    
    try {
        let baseURL = "https://api.gosky.kr";
        if (process.env.NODE_ENV === "development") {
            baseURL = "http://api.gosky.kr";
        }
      const response = await fetch(`${baseURL}/api/gosky/add-friend`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: friendUsername, userID }),
      });

      const result = await response.json();

      if (result.success) {
        // Friend exists and conversation started
        alert(`Conversation started with ${friendUsername}`);
        setFriendUsername(''); // Clear the input
        setContacts((prevContacts) => [
          ...prevContacts,
          { contactID: result.friendID, username: friendUsername },
        ]);
        window.location.reload();
      } else {
        // Show an error if the user does not exist
        setError(result.message);
      }
    } catch (error) {
      console.error('Error adding friend:', error);
    }
  };

  // Automatically scroll to the bottom when messages update
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div style={{ display: 'flex', height: '100vh', width: '70vw' }}>
      <div style={{ width: '30%', borderRight: '1px solid #ccc', padding: '10px', overflowY: 'auto' }}>
        <h2>Contact List</h2>
        <ul>
          {contacts.map((contact) => (
            <li
              key={contact.contactID}
              onClick={() => handleContactClick(contact.contactID)}
              style={{ cursor: 'pointer', padding: '5px', borderBottom: '1px solid #ddd' }}
            >
              User ID: {contact.contactID}
            </li>
          ))}
        </ul>
        {/* Friend adding section */}
        <h3>Add Friend</h3>
        <input
          type="text"
          value={friendUsername}
          onChange={(e) => setFriendUsername(e.target.value)}
          placeholder="Enter friend's username"
          style={{ width: '80%', padding: '5px' }}
        />
        <button
          onClick={handleAddFriend}
          style={{ width: '15%', padding: '5px' }}
        >
          Add
        </button>
      </div>

      <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
              flex: 1,
              padding: '10px',
              overflowY: 'auto',
              borderBottom: '1px solid #ccc',
              maxHeight: 'calc(100vh - 270px)', // Adjust height to not overflow the viewport
          }}
        >
          <h2>Chat History</h2>
          {selectedContact ? (
            <ul style={{ padding: '0', margin: '0' }}>
              {messages.map((message, index) => (
                <li
                  key={index}
                  style={{
                      textAlign: message.senderID === userID ? 'right' : 'left', // Align messages accordingly
                      padding: '5px',
                      listStyleType: 'none', // Remove default bullet points
                  }}
                >
                  <strong>{message.senderID === userID ? 'Me' : message.senderID}:</strong> {message.message}
                </li>
              ))}
              {/* Reference for auto-scrolling */}
              <div ref={chatEndRef} />
            </ul>
          ) : (
            <p>Select a contact to start chatting</p>
          )}
        </div>

        <div style={{ borderTop: '1px solid #ccc', padding: '10px' }}>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}

            placeholder="Type a message..."
            style={{ width: '80%', padding: '5px' }}
            disabled={!selectedContact}
        />
        <button
            onClick={handleSendMessage}
            style={{ width: '15%', padding: '5px' }}
            disabled={!selectedContact}
        >
            Send
        </button>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
